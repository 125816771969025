<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";

import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core/dist/index.esm";

import store from "@/state/store";
import Swal from "sweetalert2";
import {getFileUrl} from "@/helpers/api-config";

/**
 * Editors component
 */

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      menuId: 300200,
      menuCode: this.$route.params.type,
      viewData: {},
      user: {},
      formData: {
        id: "",
        answer: "",
        status: "Request",
        is_mail: true,
      },
      formSubmitted: false,
      files: [],
      type: "write",
      typeOptions: [
        { text: '답변 직접입력', value: "write" },
        { text: '전화상담 완료', value: "phone" },
      ],
    };
  },

  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },

  mounted () {
    this.initData()
    this.loadData()
  },

  validations: function() {
    return {
      formData: {
        answer: {
          required: helpers.withMessage("답변내용을 입력해 주세요.", required)
        }
      }
    }
  },

  methods: {
    initData() {
      this.formData.id = this.$route.params.id
    },

    // 데이터 로딩
    loadData() {
      let type = this.menuCode
      let id = this.formData.id
      if (id) {
        store.dispatch("apiInquiry/detail", { type, id }).then((result) => {
          this.viewData = result.data.data
          this.user = this.viewData.user

          if (this.viewData.assets) {
            this.viewData.assets.forEach(row => {
              this.files.push({
                'uid': row.uid,
                'type': (row.asset) ? row.asset.type : null,
                'name': (row.asset) ? row.asset.orgin_name : null,
                'size': (row.asset) ? row.asset.size : 0,
                'url': getFileUrl(row.uid)
              })
            })
          }

          this.formData.id = this.viewData.id
          this.formData.answer = this.viewData.answer
          this.formData.status = this.viewData.status

          if (this.viewData.status == 'Request') {
            this.formData.is_push = true;
          }
          this.answerType(this.type);

          this.$nextTick(function () {
            this.autoResizeTextarea();
          })
        })
      }

      if (!id || !this.viewData) {
        Swal.fire({
          text: "요청하신 데이터가 존재하지 않습니다.",
          icon: "warning",
          confirmButtonText: "확인",
        });
        this.$router.go(-1)
      }
    },

    // 저장여부 체크
    confirmSubmit() {
      store.dispatch('notification/clear')

      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.formData.$invalid) {
        return false;
      }

      let msg = "작성하신 내용으로 상담을 완료하시겠습니까?"
      if (this.viewData.answer) {
        msg = "답변을 수정하시겠습니까?"
      }
      Swal.fire({
        text: msg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니요"
      }).then(result => {
        if (result.value) {
          this.submitForm()
        }
      });
    },

    // 저장 서버요청
    submitForm() {
      let type = this.menuCode
      let params = {
        "id": this.formData.id,
        "answer": this.formData.answer,
        "status": 'Completed',
        "is_push": this.formData.is_push ? 1 : 0,
      }

      this.formSubmitted = true
      store.dispatch("apiInquiry/store", { type, params }).then((result) => {
        this.formSubmitted = false
        Swal.fire(result.message, "", result.success ? "success" : "error");
        window.location.reload()
      }, () => {
        this.formSubmitted = false
      });
    },

    // 삭제
    deleteData() {
      store.dispatch('notification/clear')

      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "한 번 삭제된 데이터는 복구할 수 없습니다.",
        icon: "warning",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestDeleteData()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 삭제 서버요청
    requestDeleteData() {
      let type = this.menuCode
      let params = {
        'id' : this.formData.id
      }
      if (params.id) {
        this.formSubmitted = true
        store.dispatch("apiInquiry/erase", { type, params }).then((result) => {
          Swal.fire(result.message, "", result.success ? "success" : "error");
          this.formSubmitted = false
          this.$router.push('/inquiry/home/'+ this.menuCode)
        }, () => {
          this.formSubmitted = false
        });
      }
    },

    answerType(type) {
      if (type == 'phone') {
        this.formData.answer = "안녕하세요,\n소중한 가족의 안전지킴이 앱 '도와줘' 고객지원팀입니다.\n\n고객님의 문의사항은 전화상담으로 완료되었습니다.\n\n감사합니다.";
      } else {
        this.formData.answer = this.viewData.answer;
      }
    },

    autoResizeTextarea() {
      document.querySelectorAll(".autosize").forEach(function (el) {
        let offset = el.offsetHeight - el.clientHeight;
        el.style.height = 'auto';
        el.style.height = el.scrollHeight + offset + "px";
        el.addEventListener('input', function (e) {
          e.target.style.height = 'auto';
          e.target.style.height = e.target.scrollHeight + offset + 'px';
        });
      });
    },
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    answerTitle() {
      return "답변내용"+ (this.viewData.answer_at ? " ("+ this.viewData.answer_at +")" : "")
    },
    statusName() {
      let status = '<strong class="text-success">문의가 접수되었습니다.</strong>';
      if (this.viewData.status == 'Completed') {
        status = '<strong class="text-primary">상담이 완료되었습니다.</strong>';
      }
      return status;
    }
  },

  watch: {
    type(newVal) {
      this.answerType(newVal);
      this.autoResizeTextarea();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader/>
    <div class="row">
      <div class="col-12">
        <div class="card min-content">
          <div class="card-body">
            <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>
            <b-form @submit.prevent="confirmSubmit">
              <div class="mb-3">
                <div class="mb-2">작성자</div>
                <div class="form-control bg-light">
                  {{ viewData.name }} ({{ viewData.phone }})
                </div>
              </div>
              <div class="mb-3">
                <div class="mb-2">이메일</div>
                <div class="form-control bg-light">
                  {{ viewData.email }}&nbsp;
                </div>
              </div>
              <div class="mb-2">
                <div class="mb-2">문의내용 ({{ viewData.created_at }})</div>
                <div class="form-control bg-light">
                  <div class="d-block" v-html="$nl2br(viewData.content)"></div>
                </div>
              </div>
              <div v-if="files.length" class="mb-2">
                <div class="form-control bg-light">
                  <div>
                    <p v-for="(file, index) in files" :key="index" class="mt-0 mb-1">
                      <a :href="file.url">
                        <i class="bx bx-file font-size-16 align-middle text-primary"></i>
                        {{ file.name }}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="form-control bg-light">
                  <div class="d-block" v-html="statusName"></div>
                </div>
              </div>

              <b-form-group
                  class="mb-3"
                  label="답변유형"
                  v-show="viewData.status != 'Completed' || !viewData.answer"
              >
                <b-form-radio-group
                    class="form-control bg-light"
                    v-model="type"
                    :options="typeOptions"
                ></b-form-radio-group>
              </b-form-group>

              <b-form-group
                  class="mb-3"
                  :label="answerTitle"
                  label-for="answer"
                  :invalid-feedback="v$.formData.answer.$error ? v$.formData.answer.$errors[0].$message : null"
                  :state="!v$.formData.answer.$error"
              >
                  <b-form-textarea
                      id="answer"
                      v-model="formData.answer"
                      rows="10"
                      class="autosize"
                      placeholder="답변내용을 입력해 주세요."
                      :state="!v$.formData.answer.$error"
                      :disabled="formSubmitted"
                  ></b-form-textarea>
              </b-form-group>

              <b-form-group
                  label-for="is_mail"
                  style="margin-bottom:0.3rem !important;"
              >
                <div class="form-control mb-0">
                  <b-form-checkbox id="is_mail" v-model="formData.is_mail">문의자에게 메일을 전송합니다.</b-form-checkbox>
                </div>
              </b-form-group>
              <div class="mb-3 mt-2 text-danger font-size-12">
                ※ 체크하더라도 답변내용을 입력해야 문의자에게 메일이 전송됩니다.
              </div>

              <div class="mt-4 text-start">
                <b-button variant="primary" class="me-2" :disabled="formSubmitted" @click="$router.push('/inquiry/home/'+ this.menuCode)">목록</b-button>
                <b-button variant="success" class="me-2" type="submit" :disabled="formSubmitted">
                  <span v-if="viewData.status == 'Completed' && viewData.answer">답변수정</span>
                  <span v-else>상담완료</span>
                </b-button>
                <b-button v-if="formData.id" variant="danger" class="me-2" :disabled="formSubmitted" @click="deleteData()">삭제</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
